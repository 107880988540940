export const soundsData = {
    records: [{
            title: "somewhere",
            cover: ["somewhere_cover.png", "somewhere_tracklist.png"],
            tracks: [{
                    title: 'soar',
                    length: '2:01',
                    file: 'soar_demo.wav'
                },
                {
                    title: 'nothing',
                    length: '2:26',
                    file: 'nothing_demo.wav'
                },
                {
                    title: 'peaceful',
                    length: '2:30',
                    file: 'peaceful_demo.wav'
                },
                {
                    title: 'walking the dog',
                    length: '1:24',
                    file: 'walking_the_dog_demo.wav'
                },
                {
                    title: 'good day',
                    length: '2:51',
                    file: 'good_day_demo.wav'
                },
                {
                    title: 'new leaf',
                    length: '3:20',
                    file: 'new_leaf_demo.wav'
                },
                {
                    title: 'do you',
                    length: '2:25',
                    file: 'do_you_demo.wav'
                },
                {
                    title: 'oh the places you\'ll go',
                    length: '3:15',
                    file: 'oh_the_places_youll_go_demo.wav'
                }


            ]
        },
        {
            title: "ambient",
            cover: ["ambient_cover.jpg", "ambient_back_cover.jpg"],
            tracks: [{
                    title: 'rain',
                    length: '10:01',
                    file: 'rain_demo.wav'
                },
                {
                    title: 'walking',
                    length: '15:01',
                    file: 'walking_demo.wav'
                },
                {
                    title: 'stream',
                    length: '2:01',
                    file: 'stream_demo.wav'
                },
                {
                    title: 'birds',
                    length: '1:37',
                    file: 'birds_demo.wav'
                },


            ]
        },
        {
            title: "day to day",
            cover: ["day_to_day_cover.jpg", "day_to_day_back_cover.jpg"],
            tracks: [{
                    title: 'day to day',
                    length: '10:03',
                    file: 'day_to_day_demo.wav'
                }


            ]
        },
        {
            title: "peace",
            cover: ["peace_cover_2.png", "peace_tracklist.png"],
            tracks: [{
                    title: 'like the wind',
                    length: '1:50',
                    file: 'like_the_wind_demo.wav'
                },
                {
                    title: 'hard work',
                    length: '2:22',
                    file: 'hard_work_demo.wav'
                },
                {
                    title: 'when the road meets the sky',
                    length: '2:49',
                    file: 'when_the_road_meets_the_sky_demo.wav'
                },
                {
                    title: 'mid-flight cartoon',
                    length: '2:23',
                    file: 'mid-flight_cartoon_demo.wav'
                },
                {
                    title: 'i love fall',
                    length: '2:00',
                    file: 'i_love_fall_demo.wav'
                },
                {
                    title: '.',
                    length: '2:50',
                    file: 'dot_demo.wav'
                }


            ]
        },
        {
            title: "zero",
            cover: ["zero_cover.png", "zero_tracklist.png"],
            tracks: [{
                    title: 'now until forever',
                    length: '7:56',
                    file: 'now_until_forever_demo.wav'
                },
                {
                    title: 'rumblings',
                    length: '2:32',
                    file: 'rumblings_demo.wav'
                },
                {
                    title: 'nothing to say',
                    length: '3:34',
                    file: 'nothing_to_say_demo.wav'
                }, {
                    title: 'echoes of midnight',
                    length: '3:05',
                    file: 'echoes_of_midnight_demo.wav'
                }, {
                    title: 'going places',
                    length: '2:55',
                    file: 'going_places_demo.wav'
                }, {
                    title: 'cruisin',
                    length: '4:17',
                    file: 'cruisin_demo.wav'
                }, {
                    title: 'consternation',
                    length: '3:38',
                    file: 'consternation_demo.wav'
                }, {
                    title: 'get out of my mind',
                    length: '3:29',
                    file: 'get_out_of_my_mind_demo.wav'
                }, {
                    title: 'maybe tomorrow',
                    length: '2:24',
                    file: 'maybe_tomorrow_demo.wav'
                }, {
                    title: 'who knows?',
                    length: '3:26',
                    file: 'who_knows_demo.wav'
                }, {
                    title: 'another one down',
                    length: '3:00',
                    file: 'another_one_down_demo.wav'
                }, {
                    title: 'approach',
                    length: '2:45',
                    file: 'approach_demo.wav'
                }, {
                    title: 'do you understand',
                    length: '3:15',
                    file: 'do_you_understand_demo.wav'
                }, {
                    title: 'pursuit',
                    length: '4:26',
                    file: 'pursuit_demo.wav'
                }, {
                    title: 'random number generator',
                    length: '3:51',
                    file: 'random_number_generator_demo.wav'
                }, {
                    title: 'displaced',
                    length: '5:40',
                    file: 'displaced_demo.wav'
                }, {
                    title: 'shavasana',
                    length: '3:50',
                    file: 'shavasana_demo.wav'
                }
            ]
        },
        {
            title: "leave me alone",
            cover: ["leave_me_alone_cover.png", "leave_me_alone_tracklist.png"],
            tracks: [{
                    title: 'worst day of my life (1)',
                    length: '5:13',
                    file: 'worst_day_of_my_life_demo (1).wav'
                },
                {
                    title: 'you and me',
                    length: '3:07',
                    file: 'you_and_me_demo.wav'
                },
                {
                    title: 'hey you',
                    length: '1:54',
                    file: 'hey_you_demo.wav'
                },
                {
                    title: 'together',
                    length: '2:07',
                    file: 'together_demo.wav'
                },
                {
                    title: 'hourglass',
                    length: '2:37',
                    file: 'hourglass_demo.wav'
                },
                {
                    title: 'i believe in me',
                    length: '2:52',
                    file: 'i_believe_in_me_demo.wav'
                },
                {
                    title: 'i am me',
                    length: '2:33',
                    file: 'i_am_me_demo.wav'
                },
                {
                    title: 'woohoo',
                    length: '2:00',
                    file: 'woohoo_demo.wav'
                },

            ]
        },
        {
            title: "quite content",
            cover: ["quite_content_.png", "quite_content_tracklist.png"],
            tracks: [{
                    title: 'real',
                    length: '3:35',
                    file: 'real_demo.wav'
                },
                {
                    title: 'thinking of you',
                    length: '2:16',
                    file: 'thinking_of_you_demo.wav'
                },
                {
                    title: 'everyday',
                    length: '1:42',
                    file: 'everyday_demo.wav'
                },
                {
                    title: 'i am i',
                    length: '2:42',
                    file: 'i_am_i_demo.wav'
                },
                {
                    title: 'raspberry pi',
                    length: '2:14',
                    file: 'raspberry_pi_demo.wav'
                },
                {
                    title: 'someday',
                    length: '1:32',
                    file: 'someday_demo.wav'
                },
                {
                    title: 'the adventures',
                    length: '1:50',
                    file: 'the_adventures_demo.wav'
                },
                {
                    title: 'boredom',
                    length: '2:22',
                    file: 'boredom_demo.wav'
                },
                {
                    title: 'on my own',
                    length: '1:47',
                    file: 'on_my_own_demo.wav'
                },
                {
                    title: 'i\'m free',
                    length: '1:38',
                    file: 'im_free_demo.wav'
                }
            ]
        },
        {
            title: "space doubt",
            cover: ["space_doubt.png", "space_doubt_tracklist.png"],
            tracks: [{
                    title: 'whatever this is',
                    length: '2:30',
                    file: 'whatever_this_is_demo.wav'
                },
                {
                    title: 'wow',
                    length: '2:16',
                    file: 'wow_demo.wav'
                }, {
                    title: 'pretty pretty',
                    length: '1:50',
                    file: 'pretty_pretty_demo.wav'
                }, {
                    title: 'i kinda wanna',
                    length: '3:30',
                    file: 'i_kinda_wanna_demo.wav'
                }, {
                    title: 'orbit',
                    length: '2:09',
                    file: 'orbit_demo.wav'
                }, {
                    title: 'carry on',
                    length: '3:25',
                    file: 'carry_on_demo.wav'
                }, {
                    title: 'hush',
                    length: '1:34',
                    file: 'hush_demo.wav'
                }, {
                    title: 'lilting',
                    length: '2:13',
                    file: 'lilting_demo.wav'
                }, {
                    title: 'perfect imperfections',
                    length: '2:00',
                    file: 'perfect_imperfections_demo.wav'
                }, {
                    title: 'maybe',
                    length: '2:12',
                    file: 'maybe_demo.wav'
                },
                {
                    title: 'unwind',
                    length: '1:33',
                    file: 'unwind_demo.wav'
                },
                {
                    title: 'minding my own',
                    length: '3:18',
                    file: 'minding_my_own_demo.wav'
                },
                {
                    title: '88',
                    length: '2:53',
                    file: '88_demo.wav'
                }
            ]
        },
        {
            title: "solitary refinement",
            cover: ["solitary_refinement.png", "solitary_refinement_tracklist.png"],
            tracks: [{
                    title: 'worst day of my life',
                    length: '3:13',
                    file: 'worst_day_of_my_life_demo.wav'
                },
                {
                    title: 'walking ahead',
                    length: '4:07',
                    file: 'walking_ahead_demo.wav'
                },
                {
                    title: 'the call',
                    length: '2:30',
                    file: 'the_call_demo.wav'
                },
                {
                    title: 'back to me',
                    length: '3:01',
                    file: 'back_to_me_demo.wav'
                },
                {
                    title: 'R52',
                    length: '3:16',
                    file: 'r52_demo.wav'
                },
                {
                    title: 'balcony',
                    length: '2:52',
                    file: 'balcony_demo.wav'
                },
                {
                    title: 'reaching out',
                    length: '2:47',
                    file: 'reaching_out_demo.wav'
                },
                {
                    title: 'better',
                    length: '4:21',
                    file: 'better_demo.wav'
                },
                {
                    title: 'sole',
                    length: '3:21',
                    file: 'sole_demo.wav'
                }
            ]
        },
        {
            title: "parking a lot",
            cover: ["parking_a_lot_cover.png", "parking_a_lot_tracklist.png"],
            tracks: [{
                    title: 'aliens',
                    length: '2:12',
                    file: 'aliens_demo.wav'
                },
                {
                    title: 'say anything',
                    length: '2:00',
                    file: 'say_anything_demo.wav'
                },
                {
                    title: 'abracadabra',
                    length: '2:48',
                    file: 'abracadabra_demo.wav'
                },
                {
                    title: 'honest man',
                    length: '3:40',
                    file: 'honest_man_demo.wav'
                },
                {
                    title: 'alive and ok',
                    length: '2:21',
                    file: 'alive_and_ok_demo.wav'
                },
                {
                    title: 'peace of mind',
                    length: '2:00',
                    file: 'peace_of_mind_demo.wav'
                },
                {
                    title: 'misunderstood',
                    length: '2:20',
                    file: 'misunderstood_demo.wav'
                },
                {
                    title: 'per my perspective',
                    length: '2:42',
                    file: 'per_my_perspective_demo.wav'
                },
                {
                    title: 'sweet dreams',
                    length: '1:34',
                    file: 'sweet_dreams_demo.wav'
                },
                {
                    title: 'the future',
                    length: '2:20',
                    file: 'the_future_demo.wav'
                }
            ]
        },
        {
            title: "unknown thoughts",
            cover: ["unknown_thoughts_cover.png", "unknown_thoughts_tracklist.png"],
            tracks: [{
                    title: 'unknown',
                    length: '2:10',
                    file: 'unknown_demo.wav'
                },
                {
                    title: 'sky',
                    length: '2:14',
                    file: 'sky_demo.wav'
                },
                {
                    title: 'who are you',
                    length: '2:20',
                    file: 'who_are_you_demo.wav'
                },
                {
                    title: 'so bizarre',
                    length: '2:06',
                    file: 'so_bizarre_demo.wav'
                },
                {
                    title: 'everyday despair',
                    length: '3:31',
                    file: 'everyday_despair_demo.wav'
                },
                {
                    title: 'looming',
                    length: '3:47',
                    file: 'looming_demo.wav'
                },
                {
                    title: 'a lot',
                    length: '2:20',
                    file: 'a_lot_demo.wav'
                },
                {
                    title: 'phone home',
                    length: '2:18',
                    file: 'phone_home_demo.wav'
                },
                {
                    title: 'wasted',
                    length: '2:49',
                    file: 'wasted_demo.wav'
                },
                {
                    title: 'walking in the rain',
                    length: '1:33',
                    file: 'walking_in_the_rain_demo.wav'
                }
            ]
        }
    ]
}