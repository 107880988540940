import React, { useEffect, useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import axios from "axios"
import SEO from "../components/seo"
import Login from "../components/login"
import { Helmet } from "react-helmet"
import Footer from "../components/footer"
import Scrollable from "../components/scrollable"
import headerStyles from "../styles/header.module.css"
import ModeContext from "../context/ModeContext"
import { P } from "caniuse-lite/data/agents"
import { soundsData } from "../data/sounds.js"


const SoundPage = () => {

    const { dark, toggleDark } = useContext(ModeContext)
    const [nowPlaying, setNowPlaying] = useState(0)
    const [play, setPlay] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [percentCurrentTime, setPercentCurrentTime] = useState(0)
    const [loop, setLoop] = useState(false)
    const [sounds, setSounds] = useState(soundsData)
    const [currentRecord, setCurrentRecord] = useState(soundsData[0])
    const [showTracks, setShowTracks] = useState(false)
    const [cover, setCover] = useState(true)


    useEffect(() => {


        // window.location.replace('https://google.com')

        // console.log(games)
        if (localStorage.getItem('currentTrack')) {
            setNowPlaying(localStorage.getItem('currentTrack'))
        } else {
            localStorage.setItem('currentTrack', 0)
        }

        console.log(soundsData)



    }, [])

    const getCurrentTime = () => {
        const player = document.getElementById('audioPlayer')
        const currentSongLength = currentRecord.tracks[nowPlaying].length.split('')[0] * 60
        // console.log(currentSongLength)
        // console.log(player.currentTime)

        if (player === null) {
            return
        }

        setCurrentTime(player.currentTime)

        const percentPlayed = (Math.floor(player.currentTime) / currentSongLength) * 100
        // console.log(percentPlayed.toFixed(0))
        setPercentCurrentTime(percentPlayed.toFixed(0))


        setTimeout(() => {
            getCurrentTime()
        }, 1000)
    }

    const toggleAudio = () => {
        const player = document.getElementById('audioPlayer')

        if (play) {
            player.pause()
        } else {
            player.play()
            getCurrentTime()
        }

        setPlay(!play)
    }

    const selectTrack = (s) => {
        const trackNumber = currentRecord.tracks.map(i => i.title).indexOf(s.title)
        setNowPlaying(trackNumber)
        localStorage.setItem('currentTrack', trackNumber)
        console.log(trackNumber)

        const player = document.getElementById('audioPlayer')

        if (play) {
            player.pause()
            player.load()
            player.play()
            getCurrentTime()

        } else {
            player.load()
            player.play()
            getCurrentTime()
            setPlay(!play)
        }


        // setPlay(!play)

    }

    const nextTrack = () => {

        if (nowPlaying >= currentRecord.tracks.length - 1) {
            localStorage.setItem('currentTrack', 0)
            setNowPlaying(0)
        } else {
            localStorage.setItem('currentTrack', (Number(nowPlaying) + 1))
            setNowPlaying((Number(nowPlaying) + 1))
        }
        // console.log(trackNumber)

        const player = document.getElementById('audioPlayer')

        if (play) {
            player.pause()
            player.load()
            player.play()
            getCurrentTime()
        } else {
            player.load()
            player.play()
            getCurrentTime()
        }


        // setPlay(!play)
    }

    const previousTrack = () => {

        if (nowPlaying <= 0) {
            setNowPlaying(sounds.length - 1)
            localStorage.setItem('currentTrack', currentRecord.tracks.length - 1)
        } else {
            setNowPlaying(nowPlaying - 1)
            localStorage.setItem('currentTrack', (nowPlaying - 1))
        }
        // console.log(trackNumber)

        const player = document.getElementById('audioPlayer')

        if (play) {
            player.pause()
            player.load()
            player.play()
            getCurrentTime()
        } else {
            player.load()
            player.play()
            getCurrentTime()
        }
    }


    return (
        <div className={dark ? "dark" : "light"} style={{ overflow: 'hidden', cursor: 'default' }}>

            <Helmet>
                <script
                    src="https://kit.fontawesome.com/e4bfb220a1.js"
                    crossorigin="anonymous"
                ></script>

            </Helmet>

            <SEO title="sounds" />

            <a href="/" className={headerStyles.logo} style={{ zIndex: '100' }}>
                {/* <img src={dark ? "../sup_logo_word_tb_white.gif" : "../sup_logo_word_tb.gif"} alt="sup." /> */}
                <img style={{ transform: 'translateY(-12px)' }} src={dark ? "../sup_tb_w.png" : "../sup_tb.png"} alt="sup." />
                {/* <img style={{transform: 'translateY(-12px)'}} src={"../sup_tb.png"} alt="sup." /> */}
            </a>

            <button
                className={dark ? `toggleDark` : `toggle`}
                onClick={() => { toggleDark(); navigator.vibrate(50) }}
            >
                <img src={dark ? "../circle_g.png" : "../circle.png"} style={{ transform: 'translateY(4px)' }}></img>
            </button>

            {showTracks ?
                <>
                    <div onClick={() => { setShowTracks(false); setCurrentRecord(soundsData[0]); navigator.vibrate(50); if (play) { toggleAudio() } }} style={{ textDecoration: 'none' }}><img src={dark ? "../q_note_w.png" : "../q_note.png"}
                        className={dark ? `toggleDark` : `toggle`} style={{ transform: 'translate(0px, 50px) rotate(-8deg)', scale: '.9' }}></img>
                    </div>
                </>
                :
                <>
                    <div onClick={() => { setCover(!cover); navigator.vibrate(50) }} style={{ textDecoration: 'none', transform: 'translate(0px, 42px)' }}>
                        <img src={dark ? "../rotate_g.png" : "../rotate.png"} className={dark ? `toggleDark` : `toggle`} style={{ color: dark ? 'rgba(255,255,255, .75)' : 'rgba(41, 41, 41, .85)', scale: '.7' }}></img>
                    </div>
                </>
            }

            {currentRecord &&

                <>
                    <audio loop={loop ? true : false} id="audioPlayer" style={{ display: 'none' }} onEnded={() => { nextTrack() }}>
                        <source src={`../records/${currentRecord.title}/${currentRecord.tracks[nowPlaying].file}`} type="audio/ogg" />
                        Your browser does not support the audio element.
                    </audio>

                    <p style={{ position: 'absolute', top: '42px', left: '50%', transform: 'translateX(-50%)', textAlign: 'center', fontSize: '12px', color: dark ? 'rgba(255,255,255,.5)' : 'rgba(41,41,41,.5)', fontFamily: 'monospace' }}>{currentRecord.title}</p>

                    <p style={{ position: 'absolute', fontFamily: 'monospace', fontSize: '14px', color: dark ? 'rgba(255,255,255,.75)' : 'rgba(41,41,41,.75)', textAlign: 'center', top: '20px', left: '50%', transform: 'translateX(-50%)', width: '86%' }}>{currentRecord.tracks[nowPlaying].title}</p>




                    <div style={{ position: 'absolute', height: '2px', width: '250px', background: dark ? 'rgba(255,255,255,.5)' : 'rgba(41,41,41,.5)', bottom: '150px', left: '50%', transform: 'translateX(-50%)' }}>
                        <div style={{ position: 'absolute', height: '2px', width: `${percentCurrentTime >= 100 ? 100 : percentCurrentTime}%`, background: 'rgba(51, 255, 51, 0.75)', top: '10%', left: '50%', transform: 'translateX(-50%)' }}>
                        </div>
                    </div>
                </>
            }

            {showTracks ?

                <Scrollable>



                    {currentRecord.tracks.map((s) => (
                        <div className={dark ? "soundListItemDark" : "soundListItem"} style={{ position: 'relative', height: '90px', width: '86%', backgroundColor: currentRecord.tracks[nowPlaying].title == s.title ? (dark ? 'rgba(255,255,255,.04)' : 'rgba(41,41,41,.04)') : '' }} onClick={() => { selectTrack(s); }}>
                            <p style={{ position: 'absolute', paddingTop: '18px', paddingLeft: '8px', fontFamily: 'monospace', color: dark ? 'rgba(255,255,255,1)' : 'rgba(41,41,41,1)' }}>{s.title}</p>
                            <p style={{ position: 'absolute', paddingTop: '18px', fontFamily: 'monospace', color: dark ? 'rgba(255,255,255,.75)' : 'rgba(41,41,41,.75)', right: '8px', display: currentRecord.tracks[nowPlaying].title == s.title ? 'none' : '' }}>{s.length}</p>

                            {currentRecord.tracks[nowPlaying].title == s.title &&
                                <>
                                    <p style={{ position: 'absolute', paddingTop: '18px', color: 'rgba(51, 255, 51, 0.75)', fontFamily: 'monospace', right: '8px' }}>{`${Math.floor(currentTime / 60)}:${Math.floor(currentTime % 60) >= 10 ? Math.floor(currentTime % 60) : `0${Math.floor(currentTime % 60)}`}`}</p>

                                    <div className={play ? "blink" : ""} style={{ position: 'absolute', top: '62px', left: '8px', width: '8px', height: '8px', borderRadius: '8px', background: 'rgba(51, 255, 51, 0.75)', opacity: play ? "1" : '.5' }}></div>

                                    <i style={{ position: 'absolute', paddingTop: '56px', left: '42px', transform: 'translateX(-50%)', fontSize: '18px', color: !loop ? (dark ? 'rgba(255,255,255,.25)' : 'rgba(41,41,41,0.25)') : 'rgba(51, 255, 51, 0.5)' }} className="fas fa-infinity" onClick={() => { setLoop(!loop) }}></i>

                                    <a style={{ textDecoration: 'none', cursor: 'pointer' }} href={`../${s.file}`} download><i style={{ position: 'absolute', paddingTop: '55px', color: dark ? 'rgba(255,255,255,.25)' : 'rgba(41,41,41,.25)', right: '8px', fontSize: '16px' }} className="fas fa-download"></i></a>
                                </>


                            }
                        </div>

                    ))}




                </Scrollable>



                :


                <Scrollable>

                    <>

                        {soundsData.records.map((r) => (

                            <div onClick={() => { setCurrentRecord(r); setNowPlaying(0); setShowTracks(!showTracks); }} style={{ width: '350px', height: '350px', marginBottom: '100px', boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)' }}>
                                <img src={cover ? `../covers/${r.cover[0]}` : `../covers/${r.cover[1]}`} alt={`${r.title} cover`} />
                                <p style={{ textAlign: 'center', fontSize: '18px', fontFamily: 'monospace', color: dark ? 'rgba(255,255,255,.75)' : 'rgba(41,41,41,.75)' }}>{r.title}</p>
                            </div>


                        ))}

                    </>


                </Scrollable>

            }


            {currentRecord &&

                <>

                    <i style={{ position: 'absolute', bottom: '86px', left: '25%', transform: 'translateX(-50%)', fontSize: '32px', opacity: '1', color: dark ? 'rgba(255,255,255,.9)' : 'rgba(41,41,41,.9)', zIndex: '100' }} className="fas fa-step-backward" onClick={() => { previousTrack() }}></i>

                    <i style={{ position: 'absolute', bottom: '86px', left: '50%', transform: 'translateX(-50%)', fontSize: '32px', color: dark ? 'rgba(255,255,255,.9)' : 'rgba(41,41,41,.9)', zIndex: '100' }} className={play ? "fas fa-pause" : "fas fa-play"} onClick={() => { toggleAudio(); }}></i>


                    <i style={{ position: 'absolute', bottom: '86px', left: '75%', transform: 'translateX(-50%)', fontSize: '32px', opacity: '1', color: dark ? 'rgba(255,255,255,.9)' : 'rgba(41,41,41,.9)', zIndex: '100' }} className="fas fa-step-forward" onClick={() => { nextTrack() }}></i>

                </>

            }



            <Footer></Footer>
        </div >
    )
}

export default SoundPage